import { useEffect } from 'react';

const useFetchMetaData = (setResponseMetaData, data, uniqueNames, epoch) => {
  useEffect(() => {
    const fetchData = async () => {
      const dbUid = data?.request?.targets[0]?.datasource?.uid;

      if (dbUid === undefined || uniqueNames.length === 0) {
        return;
      }

      const dataSource = `/api/datasources/proxy/uid/${dbUid}/query`;
      // this select will give you all and only neccessary tags: V0001, V0002, ...
      // only those tags should be used in the graph from the actual data query

      const queryParams = new URLSearchParams({
        db: 'dbName',
        q: `SELECT  "metadata" FROM "autogen"."${uniqueNames[0]}_metadata" WHERE time > ${epoch}  ORDER BY time DESC LIMIT 1`,
      });

      try {
        const response = await fetch(`${dataSource}?${queryParams}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        const responseData = await response.json();

        setResponseMetaData(responseData?.results[0]?.series[0]?.values[0][1]);
      } catch (error) {
        console.error('Error making query to InfluxDB');
      }
    };

    fetchData();
  }, [data, uniqueNames, setResponseMetaData, epoch]);
};

export default useFetchMetaData;
