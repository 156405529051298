import { LegendDisplayMode, VizLegend } from '@grafana/ui';
import React from 'react';

export const LegendDisplay = ({ series }) => (
  <div className="legend">
    <VizLegend
      placement="right"
      displayMode={LegendDisplayMode.List}
      items={Object.keys(series).map((key) => ({
        label: series[key].name,
        color: series[key].color,
        yAxis: 0,
        disabled: !series[key].show,
      }))}
    />
  </div>
);
