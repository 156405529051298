import { colorList, seriesDisplayModes } from 'utils/displayOptions';
import { isEqual } from 'lodash';

export function useSeriesUpdate(uniqueNames, options, onOptionsChange) {
  const updatedSeries = uniqueNames.reduce((acc, name) => {
    acc[name] = options.series?.[name] || {
      show: true,
      resize: { offset: 0, step: 1 },
      displayMode: seriesDisplayModes[0],
      color: colorList[Math.floor(Math.random() * colorList.length)],
      name,
    };
    return acc;
  }, {});

  if (!isEqual(options.series, updatedSeries)) {
    onOptionsChange({ ...options, series: updatedSeries });
  }
}
