export const updateMinMax = (
  valX: number,
  valY: number,
  minMax: { yMin: number | null; yMax: number | null; xMin: number | null; xMax: number | null }
) => {
  minMax.yMin = minMax.yMin == null || valY < minMax.yMin ? valY : minMax.yMin;
  minMax.yMax = minMax.yMax == null || valY > minMax.yMax ? valY : minMax.yMax;
  minMax.xMin = minMax.xMin == null || valX < minMax.xMin ? valX : minMax.xMin;
  minMax.xMax = minMax.xMax == null || valX > minMax.xMax ? valX : minMax.xMax;
};

export const updateDataPackage = (
  dataPackage: { dataStreams: { [key: string]: any[] } },
  table: string,
  valX: number,
  valY: number
) => {
  dataPackage.dataStreams[table].push({ valueX: valX, valueY: valY });
};
