import { PanelPlugin } from '@grafana/data';
import { SimplePanel } from './components/SimplePanel';
import { xAxisScaleModes, yAxisScaleModes } from 'utils/displayOptions';
import { SeriesOptions } from 'PanelOptions/SeriesOptions';

export const plugin = new PanelPlugin(SimplePanel).setNoPadding().setPanelOptions((builder) => {
  builder
    .addBooleanSwitch({
      path: 'showX',
      name: 'Show',
      defaultValue: true,
      category: ['X axis'],
    })
    .addSelect({
      path: 'xAxisScaleMode',
      name: 'Scale',
      category: ['X axis'],
      defaultValue: 0,
      settings: {
        options: xAxisScaleModes,
      },
    })
    .addNumberInput({
      path: 'xMinum',
      name: 'X-Min',
      category: ['X axis'],
      showIf(currentOptions) {
        return currentOptions.xAxisScaleMode === 0;
      },
      defaultValue: 0,
    })
    .addNumberInput({
      path: 'xMiniumLog',
      name: 'X-Min',
      category: ['X axis'],
      showIf(currentOptions) {
        return currentOptions.xAxisScaleMode === 1;
      },
      defaultValue: 0.1,
      settings: {
        min: 0.00000000001,
      },
    })
    .addNumberInput({
      path: 'Xmaximum',
      name: 'X-Max',
      category: ['X axis'],
    })
    .addBooleanSwitch({
      path: 'sciX',
      name: 'Scientific notation',
      defaultValue: false,
      category: ['X axis'],
    })
    .addNumberInput({
      path: 'decimalX',
      name: 'Decimals',
      category: ['X axis'],
      defaultValue: 2,
      showIf(currentOptions) {
        return currentOptions.sciX === false;
      },
      settings: {
        min: 0,
      },
    })
    .addTextInput({
      path: 'labelX',
      name: 'Label',
      defaultValue: '',
      category: ['X axis'],
    })
    // Y axis
    .addBooleanSwitch({
      path: 'showY',
      name: 'Show',
      defaultValue: true,
      category: ['Y axis'],
    })
    .addSelect({
      path: 'yAxisScaleMode',
      name: 'Scale',
      category: ['Y axis'],
      defaultValue: 0,
      settings: {
        options: yAxisScaleModes,
      },
    })
    //PhRefPoint
    .addNumberInput({
      path: 'dbValue',
      name: 'DB value',
      category: ['Y axis'],
      defaultValue: 1,
      showIf(currentOptions) {
        return currentOptions.yAxisScaleMode === 3;
      },
    })
    .addNumberInput({
      path: 'dbRefPoint',
      name: 'DB ref',
      category: ['Y axis'],
      defaultValue: 0,
      showIf(currentOptions) {
        return currentOptions.yAxisScaleMode === 3;
      },
    })
    .addNumberInput({
      path: 'Yminum',
      name: 'Y-Min',
      category: ['Y axis'],
    })
    .addNumberInput({
      path: 'Ymaximun',
      name: 'Y-Max',
      category: ['Y axis'],
    })
    .addBooleanSwitch({
      path: 'sciY',
      name: 'Scientific notation',
      defaultValue: false,
      category: ['Y axis'],
      showIf(currentOptions) {
        return currentOptions.yAxisScaleMode !== 3;
      },
    })
    .addNumberInput({
      path: 'decimalY',
      name: 'Decimals',
      defaultValue: 1,
      showIf(currentOptions) {
        return currentOptions.sciY === false;
      },
      category: ['Y axis'],
      settings: {
        min: 0,
      },
    })
    .addTextInput({
      path: 'labelY',
      name: 'Label',
      category: ['Y axis'],
    })
    .addCustomEditor({
      id: 'series',
      path: 'series',
      name: 'series',
      editor: SeriesOptions,
      category: ['Series'],
      settings: {},
    });

  return builder;
});
