export function formatYLogarithmic(value, decimalPlaces = 2, sciY = false, threshold = 0.01) {
  // Always use scientific notation if sciY is true and value is below threshold
  if (sciY || value < threshold) {
    return value.toExponential(decimalPlaces).replace(/\.?0+e/, 'e');
  }

  // For values above the threshold or when not using scientific notation
  return value.toFixed(decimalPlaces);
}

export function formatYLinear(value, sciY = false, decimalPlaces = 2, threshold = 1e-6) {
  // Basic implementation for illustration; adjust according to your specific formatting logic
  if (sciY || value < threshold) {
    return value.toExponential(decimalPlaces).replace(/\.?0+e/, 'e');
  }
  return value.toFixed(decimalPlaces);
}

export function formatXLabels(value, useSciNotation = false, decimalPlaces = 2, threshold = 0.01) {
  // Check if the absolute value is below the threshold or if scientific notation is forced
  const isBelowThreshold = Math.abs(value) < threshold && value !== 0;
  useSciNotation = useSciNotation || isBelowThreshold;

  // Adjust decimalPlaces for values below 1 to ensure proper scientific notation formatting
  if (isBelowThreshold && !useSciNotation) {
    // For values below the threshold but not using scientific notation, adjust decimal places
    decimalPlaces = Math.max(0, decimalPlaces - Math.floor(Math.log10(threshold)) - 1);
  }

  if (useSciNotation) {
    // Directly format in scientific notation
    let formattedValue = value.toExponential(decimalPlaces);
    // Ensure removing unnecessary trailing zeros after the decimal point in the exponent part
    return formattedValue.replace(/\.?0+e/, 'e');
  } else {
    // Standard decimal formatting for values above the threshold
    return value.toFixed(decimalPlaces);
  }
}
