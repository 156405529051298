import { isEqual } from 'lodash';

interface Metadata {
  rowStep: number;
  rowUnit?: string;
  rowCount?: number;
  unit?: string;
  columnUnit?: string;
}

export function extractMetadataValues(data, options, onOptionsChange) {
  if (!data) {
    return {};
  }

  const metadata: Metadata = JSON.parse(data);

  const updatedMetadataValues: Metadata = {
    rowStep: metadata.rowStep,
    unit: metadata.rowUnit,
    rowCount: metadata.rowCount,
    columnUnit: metadata.columnUnit,
  };

  if (!isEqual(options.metadataValues, updatedMetadataValues)) {
    const labelX = `Frequency (${updatedMetadataValues?.unit})`;
    const labelY = updatedMetadataValues?.columnUnit === '-' ? '' : `${updatedMetadataValues?.columnUnit}`;
    onOptionsChange({ ...options, metadataValues: updatedMetadataValues, labelX: labelX, labelY: labelY });
  }

  return updatedMetadataValues;
}
