import React, { useMemo, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { processData } from '../processing/dataProcessing';
import P5Sketch from '../sketch/P5Sketch';
import { useNormalizedNames } from 'hooks/useNormalizedNames';
import { extractMetadataValues } from 'utils/extractMetadataValues';
import { LegendDisplay } from './LegendDisplay';
import { useSeriesUpdate } from 'hooks/useSeriesUpdate';
import useFetchMetaData from 'hooks/useFetchMetaData';
import { filterSeriesByMetadata } from 'utils/metadataUtils';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ data, width, height, onOptionsChange, options, timeRange }) => {
  const [responseMetaData, setResponseMetaData] = useState(null);

  const isoDateTo = timeRange?.to?.toISOString();
  const epoch = new Date(isoDateTo).getTime();

  const uniqueNames = useNormalizedNames(data.series);

  useSeriesUpdate(uniqueNames, options, onOptionsChange);

  useFetchMetaData(setResponseMetaData, data, uniqueNames, epoch);

  const metaData = extractMetadataValues(responseMetaData, options, onOptionsChange);

  // Filter series to get only the matching elements from the metadata
  const filteredDataSeries = filterSeriesByMetadata(data, metaData);

  const dataPackage = useMemo(
    () => processData(filteredDataSeries, options, onOptionsChange, options.series, metaData),
    [filteredDataSeries, options, onOptionsChange, metaData]
  );

  // console.log(dataPackage, 'dataPackage');

  // console.log(metaData, 'metaData');
  // console.log(options, 'options');

  return (
    <>
      <div>
        <P5Sketch
          options={options}
          series={options.series}
          dataPackage={dataPackage}
          width={width}
          panelHeight={height}
          metaData={metaData}
          onOptionsChange={onOptionsChange}
        />
        <LegendDisplay series={options.series ?? {}} />
      </div>
    </>
  );
};
