import React from 'react';
import { ColorPicker, Input, Label, Select, Switch } from '@grafana/ui';
import { seriesDisplayModes } from '../utils/displayOptions';

interface DisplayMode {
  description: string;
  label: string;
  value: number;
}

interface Resize {
  offset: number;
  step: number;
}

interface SeriesItem {
  color: string;
  displayMode: DisplayMode;
  name: string;
  resize: Resize;
  show: boolean;
}

export interface Series {
  [key: string]: SeriesItem;
}

export const SeriesOptions: React.FC<any> = ({ onChange, context }) => {
  const handleValueChange = (newValue, type, name) => {
    const isOffsetOrStep = type === 'offset' || type === 'step';
    const updatedSeries = isOffsetOrStep
      ? {
          ...context.options.series[name],
          resize: {
            ...context.options.series[name].resize,
            [type]: isNaN(parseInt(newValue, 10)) ? 1 : parseInt(newValue, 10),
          },
        }
      : {
          ...context.options.series[name],
          [type]: newValue,
        };
    const updatedValues = {
      ...context.options.series,
      [name]: updatedSeries,
    };

    onChange(updatedValues);
  };
  return (
    <>
      {context.options.series === undefined ? (
        <></>
      ) : (
        Object.keys(context.options.series).map((name) => (
          <>
            <Label style={{ paddingTop: '10px', paddingBottom: '5px' }}>Show series </Label>
            <Switch
              label="Show series"
              value={context.options.series[name].show}
              onChange={(e) => handleValueChange(e.currentTarget.checked, 'show', name)}
            />
            <Label style={{ paddingTop: '10px', paddingBottom: '5px' }}>Offset </Label>
            <Input
              type="number"
              name="offsetSeries"
              label="Offset"
              defaultValue={0}
              min={0}
              onChange={(value) => handleValueChange(value.currentTarget.value, 'offset', name)}
            />
            <Label style={{ paddingTop: '10px', paddingBottom: '5px' }}>Step </Label>
            <Input
              name="stepSeries"
              type="number"
              label="Step"
              defaultValue={1}
              placeholder="none"
              min={1}
              onChange={(value) => handleValueChange(value.currentTarget.value, 'step', name)}
            />
            <Label style={{ paddingTop: '10px', paddingBottom: '5px' }}>Type</Label>
            <Select
              options={seriesDisplayModes}
              onChange={(option) => handleValueChange(option, 'displayMode', name)}
              defaultValue={seriesDisplayModes[0]}
            />
            <Label style={{ paddingTop: '10px', paddingBottom: '5px' }}>Label</Label>
            <Input
              label="Label"
              value={context.options.series[name].name}
              onChange={(value) => handleValueChange(value.currentTarget.value, 'name', name)}
            />
            <Label style={{ paddingTop: '10px', paddingBottom: '5px' }}>Color</Label>
            <ColorPicker
              color={context.options.series[name].color}
              onChange={(color) => handleValueChange(color, 'color', name)}
            />
          </>
        ))
      )}
    </>
  );
};
