import { seriesData } from 'types';
import { updateDataPackage } from 'utils/dataHelpers';
// import { calculateValX } from 'utils/mathUtils';

export const processData = (data, options, onOptionsChange, values, metaDataRowCount) => {
  if (values === undefined && typeof data?.length === 'undefined') {
    return;
  }

  if (metaDataRowCount && metaDataRowCount.rowCount && data.length > metaDataRowCount.rowCount) {
    data.series = data.series.slice(0, metaDataRowCount.rowCount);
  }

  let series = values;
  if (typeof options.series !== 'undefined') {
    series = options.series;
  }

  if (!data?.request) {
    if (Object.keys(options.series).length !== 0) {
      onOptionsChange({ ...options, series: {} });
    }
    return;
  }

  let dataPackage: seriesData = { dataStreams: {}, length: 0 };

  let yMin = null;
  let yMax = null;
  let xMin = null;
  let xMax = null;

  let tables: any = [];

  data.request.targets.forEach((element: any) => {
    if (element.measurement != null && !tables?.includes(element.measurement)) {
      tables.push(element.measurement);
      dataPackage.dataStreams[element.measurement] = [];
    }
  });

  function calculateValY(avg: number, count: number, options: any): number {
    if (options.yAxisScaleMode === 3) {
      if (count === 0 || options.dbValue === 0) {
        return 0;
      }

      const divisionResult = avg / count / (options.dbValue / Math.pow(10, options.dbRefPoint / 20));

      let valY: number;
      if (divisionResult >= 1) {
        valY = 20 * Math.log10(divisionResult);
      } else {
        valY = -20 * Math.log10(1 / divisionResult);
      }
      return isNaN(valY) ? 1 : valY;
    }

    return isNaN(avg) ? 0 : avg;
  }

  tables.forEach((table) => {
    if (typeof series[table] === 'undefined' || !series[table].show) {
      return;
    }

    // this is not sorted data this is assigning the data to the right series
    //.split('.')[0] is removing from the name the .0000 and .0001 etc. in previous version
    // there were multiple measurements
    let sortedData = data.series.filter((x) => x.name.split('.')[0] === table);

    // console.log(sortedData, 'sortedData');

    let clusterNum = sortedData.length;
    let count = Math.ceil(sortedData.length / clusterNum);
    let start = 0,
      numOfData = 0;

    for (let i = 0; i < clusterNum; i++) {
      let dataSlice = sortedData.slice(start, start + count);

      let sum = dataSlice.reduce((acc, element) => acc + element.fields[1].values[0], 0);

      let c = dataSlice.length;

      let valY = calculateValY(sum, c, options);

      let valX = calculateValX(series[table], numOfData, options);

      updateDataPackage(dataPackage, table, valX, valY);

      // Update min/max for both X and Y
      updateMinMax(valX, valY);

      start += count;
      numOfData++;
    }
  });

  function calculateValX(seriesItem, numOfData, options) {
    let valX = seriesItem.resize.step * numOfData;

    return options.xAxisScaleMode === 1 && valX < 1 ? 1 : valX;
  }

  function updateMinMax(valX, valY) {
    yMin = yMin == null || valY < yMin ? valY : yMin;
    yMax = yMax == null || valY > yMax ? valY : yMax;
    xMin = xMin == null || valX < xMin ? valX : xMin;
    xMax = xMax == null || valX > xMax ? valX : xMax;
  }

  dataPackage['range'] = {
    xMin: xMin,
    xMax: xMax,
    yMin: yMin,
    yMax: yMax,
  };

  tables.forEach((table) => {
    if (typeof series[table] === 'undefined' || !series[table].show) {
      return;
    }

    dataPackage.dataStreams[table].forEach((element) => {
      const checkXmax = xMax === null ? 0 : xMax;

      switch (options.xAxisScaleMode) {
        case 0:
          Object.assign(element, { xPos: element.valueX / checkXmax });
          break;
        case 1:
          Object.assign(element, {
            xPos: Math.log10(element.valueX) / Math.log10(checkXmax),
          });
          break;
      }
      switch (options.yAxisScaleMode) {
        case 0:
          Object.assign(element, { yPos: element.valueY });
          break;
        case 1:
          Object.assign(element, {
            yPos: element.valueY >= 1 ? Math.log10(element.valueY) : -Math.log10(1 / element.valueY),
          });
          break;
        case 3:
          Object.assign(element, { yPos: element.valueY });
          break;
      }
    });
  });

  if (options.Yminum === undefined && options.Ymaximun === undefined) {
    options.overrideLimitY = false;
  } else {
    options.overrideLimitY = true;
  }

  if (options.xMinum === undefined || (options?.xMiniumLog && options.Xmaximum === undefined)) {
    options.overrideLimitX = false;
  } else {
    options.overrideLimitX = true;
  }

  dataPackage['length'] = Object.values(dataPackage.dataStreams)[0]?.length ?? 0;

  if (dataPackage['length'] === 0) {
    return;
  }

  return dataPackage ?? {};
};
