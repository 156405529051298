export const filterSeriesByMetadata = (data, metaData) => {
  if (data?.series?.length <= 1) {
    return;
  }

  const lengthMetaData = metaData?.rowCount?.toString()?.length + 1;

  return {
    ...data,
    series: data.series
      .map((seriesItem) => {
        const nameFields = seriesItem?.name?.split('_');
        const nameFieldsIndexes = nameFields[nameFields?.length - 1];
        const lengthNameFieldsIndexes = nameFieldsIndexes?.length;

        return lengthNameFieldsIndexes === lengthMetaData ? seriesItem : null;
      })
      .filter((filteredSeries) => filteredSeries !== null),
  };
};
