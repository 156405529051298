const xAxisScaleModes = [
  { label: 'Linear', value: 0, description: 'A linear display axis, used for diplaying numerical data.' },
  {
    label: 'Logarithmic',
    value: 1,
    description: 'A logarithmic display axis, used for diplaying specific numerical data like sound.',
  },
];

const yAxisScaleModes = [
  { label: 'Linear', value: 0, description: 'A linear display axis, used for diplaying numerical data.' },
  {
    label: 'Logarithmic',
    value: 1,
    description: 'A logarithmic display axis, used for diplaying specific numerical data like sound.',
  },
  {
    label: 'Decibel',
    value: 3,
    description:
      'A display simillar to the logarithmic scale, but better equiped to visualize sound that is hearable to humans.',
  },
];

const colorList = ['orange', 'green', 'blue', 'red', 'purple', 'yellow'];

const seriesDisplayModes = [
  {
    label: 'Line',
    value: 2,
    description: 'Connects datapoints with lines.',
  },
  {
    label: 'Histogram',
    value: 0,
    description: 'Typical bar chart visualization.',
  },
  {
    label: 'Empty histogram',
    value: 1,
    description: 'Simillar to the histogram chart but hollow.',
  },
];

export { xAxisScaleModes, yAxisScaleModes, colorList, seriesDisplayModes };
