import library from './translateCollection.json';
import BigNumber from 'bignumber.js';
import React from 'react';
import Sketch from 'react-p5';
import { createCanvas } from 'p5';
import { formatYLogarithmic, formatYLinear, formatXLabels } from 'utils/formatChartNumber';

export default function P5Sketch({ width, panelHeight, options, series, dataPackage, metaData, onOptionsChange }) {
    let hzCoefficient = metaData?.rowStep ? metaData.rowStep : 1;
    let unitMetaData = metaData?.unit !== '' ? metaData.unit : '';

    if (!dataPackage || typeof dataPackage !== 'object' || !options.series || typeof options.series !== 'object' || typeof dataPackage === 'undefined' || typeof options.series === 'undefined' || Object.values(dataPackage?.range).every(el => el === null)) {
        console.log("dataPackage in the null", dataPackage);
        return null
    }

    //GLOBAL variables
    let canvas;
    let prevHeight;
    let prevWidth;
    // here you set padding of the chart
    let margin = { top: 20, bottom: 40, left: 0, right: 30 };
    let chartWindow = { width: 0, height: 0 };
    let parent;
    let panelref;
    let firstTime = true;
    let error = '';
    // calculate height of the panel chart based on number of series beacsue of legend...
    let height = panelHeight - (Object.keys(series).length * 20);

    //GRID RELATED variables
    let gridRange = { xMinium: 0, xMaximum: 1, yMinium: 0, yMaximum: 1 };
    let valueRange = { xMinium: 0, xMaximum: 0, yMinium: 0, yMaximum: 0 };
    let xLinearLabels = { xMinum: 0, xMaximum: 0 }
    let gridTicks = { x: 5, y: 5 };
    let yAxisOffset = 0;
    let barWidth;
    let ScaleMode = Object.freeze({ 'linear': 0, 'logarithmic': 1, 'decibel': 3 });
    let DisplayMode = Object.freeze({ 'histogram': 0, 'hollow': 1, 'line': 2 });

    //DATA variables
    let tempCoordinates = {};
    let drawingCoordinates = [];
    let formatNumber = require('./formatNumber.js');
    //ZOOMING variables
    let startPos = { x: 0, y: 0 };
    let deadzone = 5;
    let mousePressed;

    //TOOLTIP variables
    let tooltipActive;
    let roundX;
    let roundY;

    //MISC variables
    let localScaling = {};
    let precision = [0.1, 0.15, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6, 0.7, 0.75, 0.8, 0.9, 1.0];
    let columns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];


    const setup = (p5, canvasParentRef) => {

        p5.createCanvas(width, height).parent(canvasParentRef);

        p5.smooth();

    }


    const errorCheck = (p) => {
        if (error === '') {
            drawGrid(p);
            drawSeries(p);
        } else {
            displayError(p);
        }
    }




    const draw = (p) => {

        if (width != prevWidth || height != prevHeight) {
            prevHeight = height;
            prevWidth = width;
            p.resizeCanvas(width, height);
            p.clear();
            errorCheck(p);
        }

        if (mousePressed) {
            p.clear();
            if (error == '') {
                drawSeries(p);
                drawZoom(p);
            } else {
                displayError(p);
            }
        } else if (p.mouseX > margin.left && p.mouseX < width - margin.right && p.mouseY > margin.top && p.mouseY < height - margin.bottom) {
            p.clear();
            if (error == '') {
                drawGrid(p);
                drawSeries(p);
                hoverDatapoint(p);
            } else {
                displayError(p);
            }
        } else if (tooltipActive) {
            p.clear();
            if (error === '') {
                drawGrid(p);
                drawSeries(p);
                tooltipActive = false;
            } else {
                displayError(p);
            }
        } else {
            p.cursor('initial');
        }
    }

    // this function is called when the chart is zoomed in or out dragging the mouse
    const mouseButtonPressed = (p) => {
        if (p.mouseX < margin.left || p.mouseX > width - margin.right || p.mouseY < margin.top || p.mouseY > height - margin.bottom) {
            return;
        }
        mousePressed = true;
        startPos = { x: p.mouseX, y: p.mouseY };
    }

    const mouseReleased = (p) => {
        if (!mousePressed) { return; }
        mousePressed = false;
        if (Math.abs(startPos.x - p.mouseX) > deadzone) {

            const previousGridRanges = {
                xMinium: valueRange.xMinium * hzCoefficient, Xmaximum: valueRange.xMaximum * hzCoefficient, scale: options.xAxisScaleMode
            };

            var max = _.max([p.mouseX - margin.left, startPos.x - margin.left]) / chartWindow.width;
            var min = _.min([p.mouseX - margin.left, startPos.x - margin.left]) / chartWindow.width;
            var range = gridRange.xMaximum - gridRange.xMinium;
            var minP = gridRange.xMinium + (min * range);
            var maxP = gridRange.xMinium + (max * range);
            minP = _.clamp(minP, 0, gridRange.xMaximum);
            maxP = _.clamp(maxP, gridRange.xMinium, 1);

            // Convert gridRange values back to data points
            const convertedMin = (minP * (dataPackage.range.xMax - dataPackage.range.xMin)) + dataPackage.range.xMin
            const convertedMax = (maxP * (dataPackage.range.xMax - dataPackage.range.xMin)) + dataPackage.range.xMin

            // Update gridRange values with converted data points
            gridRange.xMinium = minP;
            gridRange.xMaximum = maxP;

            const roundConvertedMin = Math.round(convertedMin * hzCoefficient);
            const roundConvertedMax = Math.round(convertedMax * hzCoefficient);
            const isLogarithmic = options.xAxisScaleMode === ScaleMode.logarithmic

            onOptionsChange({
                ...options,
                xMinum: isLogarithmic ? 0 : roundConvertedMin,
                xMiniumLog: isLogarithmic ? roundConvertedMin : 0.1,
                Xmaximum: roundConvertedMax, previousGridRanges: previousGridRanges
            })

            // let previousGridRanges = [];

            recalculateValues({ chgH: true, chgV: true }, p);
        } else {
            mousePressed = false;
            errorCheck(p);
        }
        mousePressed = false;
    }

    // console.log(options.Xmaximum, 'valueRange.xMinium');
    // console.log(dataPackage.range.xMax, 'dataPackage.range.xMin');
    function doubleClick(p) {
        if (p.mouseX < margin.left || p.mouseX > width - margin.right || p.mouseY < margin.top || p.mouseY > height - margin.bottom) {
            return;
        }

        // Resetting based on scale mode
        onOptionsChange({
            ...options,
            xMinum: undefined,
            xMiniumLog: 0.1,
            Xmaximum: undefined,
            previousGridRanges: { xMinium: undefined, Xmaximum: undefined }
        });
        // }
        recalculateValues({ chgH: true, chgV: true }, p);
    }



    function hoverDatapoint(p) {
        p.noStroke();
        p.fill(150, 0, 0, 220);
        p.rect(p.mouseX, height - margin.bottom, 2 * 1, -chartWindow.height);
        p.cursor(p.CROSS);
        for (let i = 0; i < drawingCoordinates.length; i++) {
            if (drawingCoordinates[i].point < p.mouseX && drawingCoordinates[i].point + _.max([chartWindow.width / drawingCoordinates.length, 1]) > p.mouseX) {
                displayTooltip(drawingCoordinates[i].val, p);
                break;
            }
        }
    }

    function displayError(p) {
        p.textSize(23);
        p.textAlign(p.CENTER, p.CENTER);
        p.strokeWeight(0.5);
        p.stroke(255);
        p.fill(255, 255, 255);

        p.text(error, (width / 2), (height / 2));
    }
    function getTooltipWidth(data) {
        let maxStringLength = 0;

        for (let key in data) {
            const valueXLength = String(data[key].valueX).length;
            const valueYLength = String(data[key].valueY).length;

            maxStringLength = Math.max(maxStringLength, valueXLength, valueYLength);
        }
        const averageCharWidth = 4
        return maxStringLength * averageCharWidth;
    }


    function getTooltipHeight(data) {
        return 20 + (Object.keys(data).length * 20);
    }

    function displayTooltip(data, p) {
        tooltipActive = true;

        const tooltipWidth = getTooltipWidth(data, library);
        const tooltipHeight = getTooltipHeight(data);

        const vertMult = (p.mouseY + tooltipHeight > height - margin.bottom) ? -1 : 1;
        const horMult = (p.mouseX + tooltipWidth > width - margin.right) ? -1 : 1;

        p.fill(28, 28, 28, 255);
        p.rect(p.mouseX, p.mouseY, tooltipWidth * horMult, tooltipHeight * vertMult, 10);

        p.textSize(13);
        p.textAlign(p.CENTER, p.CENTER);
        p.strokeWeight(0.5);
        p.stroke(255);
        p.fill(255, 255, 255);

        //Tool tip text
        Object.keys(data).forEach((key, index) => {
            const toolTipY = formatNumber(data[key].valueY, options.sciX, 1);
            const item = toolTipY === 'NaN' ? undefined : toolTipY;
            p.text(`value: ${item}`, p.mouseX + ((horMult * tooltipWidth) / 2), p.mouseY + (vertMult * (20 + (20 * (index + 1)) - 10)));
            const formatScientificNotation = formatNumber(data[key].valueX * hzCoefficient, options.sciX, options.decimalX);
            p.text(`Freq: ${formatScientificNotation}`, p.mouseX + ((horMult * tooltipWidth) / 2), p.mouseY + (vertMult * 10));
        });
    }

    //visualization of zooming in 
    function drawZoom(p) {
        p.stroke(180, 180, 180, 200);
        p.fill(30, 30, 30, 150);
        p.rect(startPos.x, height - margin.bottom, p.mouseX - startPos.x, -chartWindow.height);
    }

    function linearXLabels(p) {
        let labelOffset = 0;
        const valueIncr = (valueRange.xMaximum - valueRange.xMinium) / gridTicks.x;
        const horizontalMul = chartWindow.width / gridTicks.x;


        const getLabelValue = (index) => {
            const value = valueRange.xMinium + (valueIncr * index)
            let calculateValue = (value * hzCoefficient)
            return formatXLabels(calculateValue, options.sciX, options.decimalX, 0.01);

        };

        // Draw vertical grid lines and their labels
        for (let i = 1; i < gridTicks.x; i++) {
            const xPosition = margin.left + (i * horizontalMul);
            const yPosition = height - (margin.bottom / 2);

            // Draw vertical line
            p.line(xPosition, height - margin.bottom, xPosition, margin.top);

            // Draw label for the vertical line
            const labelText = getLabelValue(i);
            p.text(labelText, xPosition, yPosition);
        }

        // first label on the left
        p.line(margin.left, height - margin.bottom, margin.left, margin.top);
        const getFirstLabel = getLabelValue(0);
        p.text(getFirstLabel, margin.left + labelOffset, height - (margin.bottom / 2));

        // last label on the right
        p.line(margin.left + chartWindow.width, height - margin.bottom, margin.left + chartWindow.width, margin.top);
        const getLastLabel = getLabelValue(gridTicks.x);
        p.text(getLastLabel, margin.left + chartWindow.width + labelOffset, height - (margin.bottom / 2));
    }

    function logarithmicLabelsX(p) {
        const checkMinimum = valueRange.xMinium <= 0 ? 1 : valueRange.xMinium

        var logIncr = 10 ** (Math.ceil(Math.log10(checkMinimum)));
        if (logIncr == checkMinimum) {
            logIncr *= 10;
        }
        let labelOffset = 0;

        while (logIncr < valueRange.xMaximum) {
            var xPos = margin.left + (chartWindow.width * ((Math.log10(logIncr) - Math.log10(valueRange.xMinium)) / (Math.log10(valueRange.xMaximum) - Math.log10(valueRange.xMinium))));
            p.line(xPos, height - margin.bottom, xPos, margin.top);

            const calculateLogValue = (logIncr * hzCoefficient)
            const formatLabel = formatXLabels(calculateLogValue, options.sciX, options.decimalX, 0.01);
            p.text(formatLabel, xPos + labelOffset, height - (margin.bottom / 2));
            logIncr *= 10;
        }

        p.textAlign(p.LEFT, p.CENTER);

        //first label
        p.line(margin.left, height - margin.bottom, margin.left, margin.top);
        const calculateLogValueFirstLabel = (valueRange.xMinium * hzCoefficient)
        const formatFirstLabel = formatXLabels(calculateLogValueFirstLabel, options.sciX, options.decimalX, 0.01);
        p.text(formatFirstLabel, margin.left + labelOffset, height - (margin.bottom / 2))

        //last label
        p.line(margin.left + chartWindow.width, height - margin.bottom, margin.left + chartWindow.width, margin.top);
        const calculateLogValueLastLabel = (valueRange.xMaximum * hzCoefficient)
        const formatLastLabel = formatXLabels(calculateLogValueLastLabel, options.sciX, options.decimalX);
        p.text(formatLastLabel, margin.left + chartWindow.width + labelOffset, height - (margin.bottom / 2));
    }

    function drawBoundaryLabels(p, chartWindow, margin, decimalPlaces) {
        // Label for maximum value
        p.textAlign(p.RIGHT, p.CENTER);
        p.line(margin.left + chartWindow.width, height - margin.bottom, margin.left + chartWindow.width, margin.top);
        const calculateMaxLogarithmicLabel = (valueRange.xMaximum * hzCoefficient)?.toFixed(decimalPlaces);
        p.text(calculateMaxLogarithmicLabel, margin.left + chartWindow.width, height - (margin.bottom / 2));
    }

    //  console.log(`value: ${value},valueRange.yMinium: ${valueRange.yMinium}, ,valueRange.yMaximum: ${valueRange.yMaximum}, gridTicks.y: ${gridTicks.y}`);

    function calculateDynamicEpsilon(yMax, latestTick) {
        const epsilonMultiplier = yMax - latestTick;
        return Math.round(epsilonMultiplier);
    }


    function linearYLabels(p) {

        let marginOff = 0;

        let exactNumIntervals = (valueRange.yMaximum - valueRange.yMinium) / gridTicks.y;
        let numTicks = Math.ceil(exactNumIntervals) + 1; // Adding 1 for the starting tick

        // Calculate the actual value of what the last tick would be without adjustments
        let actualLastTickValue = valueRange.yMinium + (numTicks - 1) * gridTicks.y;

        // Adjust if the last tick value exceeds the maximum or exactly matches it
        if (actualLastTickValue > valueRange.yMaximum || Math.abs(actualLastTickValue - valueRange.yMaximum) < Number.EPSILON) {
            numTicks--;
        }

        const verticalMul = chartWindow.height / numTicks;

        for (let i = 0; i <= numTicks; i++) {

            const value = valueRange.yMinium + i * gridTicks.y;
            const adjustedValue = value - valueRange.yMinium;
            const tickSpace = Math.round(adjustedValue / gridTicks.y);

            const yPos = calculateYPosition(tickSpace, verticalMul);

            // Adjust the calculation of labelNumber based on the original value
            let labelNumber = gridTicks.y * (value / gridTicks.y)

            const labelText = formatYLinear(labelNumber, options.sciY, options.decimalY, 1e-16);
            if (isNaN(parseFloat(labelText))) {
                return;
            }

            drawHorizontalLineAndLabel(yPos, labelText, p);

            if (labelText.length > marginOff) {
                marginOff = labelText.length;
            }
        }

        marginOff = (marginOff * 6) + 20;

        if (marginOff !== margin.left) {
            margin.left = marginOff;
            chartWindow = {
                width: width - margin.left - margin.right,
                height: height - margin.top - margin.bottom
            };
            recalculateValues({ chgH: true, chgV: true }, p);
        }
    }


    function drawHorizontalLineAndLabel(yPosition, text, p) {
        p.line(margin.left, yPosition, width - margin.right, yPosition);
        p.text(text, margin.left - 3, yPosition)
    }
    function calculateYPosition(multiplier, vMultiplier) {
        return height - margin.bottom - (vMultiplier * multiplier)
    }

    const logDrawY = (p) => {
        var marginOff = 0;
        var text = "";
        var yPos = height - margin.bottom - (chartWindow.height * yAxisOffset);

        let maxValue = Math.pow(10, valueRange.yMaximum) === 0 ? 1 : Math.pow(10, valueRange.yMaximum);
        let minValue = Math.pow(10, valueRange.yMinium) === 0 ? 1 : Math.pow(10, valueRange.yMinium);

        let maxOrderOfMagnitude = Math.floor(valueRange.yMaximum);
        let minOrderOfMagnitude = Math.floor(valueRange.yMinium);

        // Adjust maxOrderOfMagnitude if the actual maximum is close to the next order
        let adjustedMaxValue = Math.pow(10, maxOrderOfMagnitude);
        if (maxValue > adjustedMaxValue && maxValue / adjustedMaxValue < 10) {
            maxOrderOfMagnitude += 1;
        }

        for (let order = maxOrderOfMagnitude; order >= minOrderOfMagnitude; order--) {
            let num = Math.pow(10, order);
            yPos = height - margin.bottom - (chartWindow.height * ((order - minOrderOfMagnitude) / (maxOrderOfMagnitude - minOrderOfMagnitude)));

            // Check if the number is less than 0.001 to use scientific notation for overcrowded zeros
            // const scientific = options.sciY ? true : num < 0.001;

            text = formatYLogarithmic(num, options.decimalY, options.sciY, 1e-2);
            p.line(margin.left, yPos, width - margin.right, yPos);
            p.text(text, margin.left - 3, yPos);

            if (text.length > marginOff) {
                marginOff = text.length;
            }
        }

        // Adjust margin if necessary
        marginOff = (marginOff * 6) + 20;
        if (marginOff != margin.left) {
            margin.left = marginOff;
            chartWindow = { width: (width - margin.left - margin.right), height: (height - margin.top - margin.bottom) };
            recalculateValues({ chgH: true, chgV: true }, p);
        }
    }

    //Drawing Labels for X and Y axis
    function drawGrid(p) {
        p.textSize(12);
        p.textAlign(p.CENTER, p.CENTER);
        p.strokeWeight(0.5);
        p.stroke(128);
        p.fill(255);
        if (options.showX) {
            if (options.xAxisScaleMode === ScaleMode.linear) {
                linearXLabels(p);
            } else {
                logarithmicLabelsX(p);
            }

            p.textAlign(p.CENTER, p.BOTTOM);
            p.text(options.labelX, margin.left + (chartWindow.width / 2), height);
        }
        //Drawing the Y axis
        if (options.showY) {
            p.textAlign(p.RIGHT, p.CENTER);
            if (options.yAxisScaleMode !== ScaleMode.logarithmic) {
                linearYLabels(p);
            } else {
                logDrawY(p);
            }

            p.textSize(12);
            p.textAlign(p.CENTER, p.CENTER);
            p.strokeWeight(0.5);
            p.stroke(128);
            p.fill(255);

            p.push();
            p.textAlign(p.CENTER, p.TOP);
            p.rotate(p.radians(270));
            p.text(options.labelY, -(margin.top + (chartWindow.height / 2)), 0);
            p.pop();
        }
    }

    function logDataGroup(data, cnt) {
        var start = 0;
        var pixSize = (1 / (chartWindow.width) * (gridRange.xMaximum - gridRange.xMinium));
        while (start < data.length) {
            var fPoint = data[start].xPos
            var c = 1;
            while (data[start + c] != null && data[start + c].xPos < fPoint + pixSize) {
                c += 1;
            }
            var point = processDataPoints(data.slice(start, start + c), cnt);
            var percX = (point.xPos - gridRange.xMinium) / (gridRange.xMaximum - gridRange.xMinium);
            point['groupSize'] = c;
            if (percX >= 0 && percX <= 1) {
                if (tempCoordinates[point.valueX] == null) {
                    tempCoordinates[point.valueX] = { point: margin.left + (percX * chartWindow.width), val: { [cnt]: point } };
                } else {
                    tempCoordinates[point.valueX].val[cnt] = (point);
                }
            }
            start += c;
        }
    }

    function linDataGroup(data, width, cnt) {
        if (width < 0) { return; }
        var incr = Math.ceil(data.length / width);
        var start = 0;
        while (start < data.length) {
            var point = processDataPoints(data.slice(start, start + incr), cnt);
            var percX = (point.xPos - gridRange.xMinium) / (gridRange.xMaximum - gridRange.xMinium);
            point['groupSize'] = incr;
            if (percX >= 0 && percX <= 1) {
                if (tempCoordinates[point.valueX] == null) {
                    tempCoordinates[point.valueX] = { point: margin.left + (percX * chartWindow.width), val: { [cnt]: point } };
                } else {
                    tempCoordinates[point.valueX].val[cnt] = (point);
                }
            }
            start += incr;
        }
    }


    function linDataAll(data, cnt) {
        var mult = series[cnt].resize.step
        for (let i = valueRange.xMinium; i <= valueRange.xMaximum; i += series[cnt].resize.step) {
            var id = Math.floor((i - series[cnt].resize.step) / mult);
            if (data[id] != null) {
                var percX = (data[id].xPos - gridRange.xMinium) / (gridRange.xMaximum - gridRange.xMinium);

                if ((percX * chartWindow.width) >= 0 && (percX * chartWindow.width) <= chartWindow.width) {
                    if (tempCoordinates[data[id].valueX] == null) {
                        tempCoordinates[data[id].valueX] = ({ point: margin.left + (percX * chartWindow.width), val: { [cnt]: data[id] } });
                    } else {
                        tempCoordinates[data[id].valueX].val[cnt] = (data[id]);
                    }
                }
            }
        }
    }


    function storeData(data, cnt) {
        var width = chartWindow.width / (gridRange.xMaximum - gridRange.xMinium);
        if (data.length > width && options.xAxisScaleMode != ScaleMode.logarithmic) {
            linDataGroup(data, width, cnt);
        }
        else if (options.xAxisScaleMode === ScaleMode.logarithmic) {
            logDataGroup(data, cnt);
        } else {
            linDataAll(data, cnt);
        }
        processCoordinates();
    }

    // main function for calculating the data
    function processCoordinates() {
        var keys = (Object.keys(tempCoordinates));
        tempCoordinates = keys.reduce((a, c) => (a[c] = tempCoordinates[c], a), {});
        drawingCoordinates = [];
        for (let i = 0; i < keys.length; i++) {
            drawingCoordinates.push(tempCoordinates[keys[i]]);
        }
    }

    function processDataPoints(data, cnt) {
        var above = false;
        var below = false;
        var avgX = 0;
        var avgY = 0;
        var Ymaximun = null;
        var Yminum = null;
        var firstY = data[0].valueY;
        var lastY = 0;
        var count = 0;
        data.forEach(d => {
            if (d.valueY > 0) { above = true; }
            if (d.valueY < 0) { below = true; }
            avgX += d.valueX;
            avgY += d.valueY;
            if ((d.valueY) > (Ymaximun) || Ymaximun == null) {
                Ymaximun = d.valueY;
            }
            if ((d.valueY) < (Yminum) || Yminum == null) {
                Yminum = d.valueY;
            }
            count += 1;
            lastY = d.valueY;
        });
        avgX /= count;
        avgY /= count;
        if (above && below) {
            //Yminum = _.min(data.map(d => d.valueY));
            //Ymaximun = _.max(data.map(d => d.valueY));
        }
        var valY = avgY;
        var element = { valueX: data[0].valueX, valueY: valY, valueYAvg: avgY, valueYMax: Ymaximun, valueYMin: Yminum, valueYFirst: firstY, valueYLast: lastY };
        switch (options.xAxisScaleMode) {
            case (ScaleMode.linear):
                Object.assign(element, { xPos: ((((element.valueX))) / ((dataPackage.range.xMax))) });
                break;
            case (ScaleMode.logarithmic):
                Object.assign(element, { xPos: (Math.log10((element.valueX)) / Math.log10((dataPackage.range.xMax))) });
                break;
        }
        switch (options.yAxisScaleMode) {
            case (ScaleMode.linear):
                Object.assign(element, { yPos: element.valueY, yPosAvg: element.valueYAvg, yPosMax: element.valueYMax, yPosMin: element.valueYMin, yPosFirst: element.valueYFirst, yPosLast: element.valueYLast });
                break;
            case (ScaleMode.logarithmic):
                Object.assign(element, { yPos: Math.log10(element.valueY), yPosAvg: Math.log10(element.valueYAvg), yPosMax: Math.log10(element.valueYMax), yPosMin: Math.log10(element.valueYMin), yPosFirst: Math.log10(element.valueYFirst), yPosLast: Math.log10(element.valueYLast) });
                if (isNaN(element.yPos)) { element.yPos = null; }
                if (isNaN(element.yPosAvg)) { element.yPosAvg = null; }
                if (isNaN(element.yPosMax)) { element.yPosMax = null; }
                if (isNaN(element.yPosMin)) { element.yPosMin = null; }
                if (isNaN(element.yPosFirst)) { element.yPosFirst = null; }
                if (isNaN(element.yPosLast)) { element.yPosLast = null; }
                break;
            case (ScaleMode.decibel):
                Object.assign(element, { yPos: element.valueY, yPosAvg: element.valueYAvg, yPosMax: element.valueYMax, yPosMin: element.valueYMin, yPosFirst: element.valueYFirst, yPosLast: element.valueYLast });
                break;
        }
        return element;
    }

    function histogramDraw(name, yPercent, point, barWidth, p) {
        p.strokeWeight(0.1);
        p.stroke(series[name].color);
        p.fill(series[name].color);
        if ([ScaleMode.linear, ScaleMode.decibel].includes(options.yAxisScaleMode)) {
            p.rect(point, height - margin.bottom - (chartWindow.height * yAxisOffset), barWidth - 2, (chartWindow.height * yAxisOffset) - (chartWindow.height * yPercent));
        } else {
            p.rect(point, height - margin.bottom, barWidth - 2, - (chartWindow.height * yPercent));
        }
    }

    function hollowDraw(name, yPercent, point, barWidth, p) {
        p.stroke(series[name].color);
        p.strokeWeight(0.5);
        p.noFill();
        if ([ScaleMode.linear, ScaleMode.decibel].includes(options.yAxisScaleMode)) {
            p.rect(point, height - margin.bottom - (chartWindow.height * yAxisOffset), barWidth - 2, (chartWindow.height * yAxisOffset) - (chartWindow.height * yPercent));
        } else {
            p.rect(point, height - margin.bottom, barWidth - 2, - (chartWindow.height * yPercent));
        }
    }

    function dewesoftDraw(name, i, prevPoint, offset, p) {
        // console.log("pridemo do sm 2?");
        p.strokeWeight(1);
        p.stroke(series[name].color);
        p.fill(series[name].color);

        if (drawingCoordinates[i].val[name].yPos == null) { drawingCoordinates[i].val[name].yPos = valueRange.yMinium; }

        var yPercent = _.clamp((drawingCoordinates[i].val[name].yPos - valueRange.yMinium) / (valueRange.yMaximum - valueRange.yMinium), 0, 1);
        return (dewesoftSingleDraw(yPercent, i, prevPoint, offset, p));
    }


    function dewesoftSingleDraw(yPercent, i, prevPoint, offset, p) {
        if (prevPoint) {
            p.line(prevPoint.x, prevPoint.y, drawingCoordinates[i].point + offset, height - margin.bottom - (chartWindow.height * yPercent));
        }
        p.strokeWeight(3);
        //p.point(drawingCoordinates[i].point + offset, height - margin.bottom - (chartWindow.height * yPercent));
        return ({ x: drawingCoordinates[i].point + offset, y: height - margin.bottom - (chartWindow.height * yPercent) });
    }

    function groupDraw(name, yPercent, i, prevPoint, offset, p) {
        p.strokeWeight(1);
        p.stroke(series[name].color);
        p.fill(series[name].color);
        if (drawingCoordinates[i - 1] != null && drawingCoordinates[i - 1].val[name] != null && drawingCoordinates[i - 1].val[name].groupSize == 1) {
            p.line(prevPoint.x, prevPoint.y, drawingCoordinates[i].point, height - margin.bottom - (chartWindow.height * yPercent));
        }

        var minV = drawingCoordinates[i].val[name].yPosMin;
        var maxV = drawingCoordinates[i].val[name].yPosMax;

        var avg;
        if (drawingCoordinates[i - 1] != null && drawingCoordinates[i - 1].val[name] != null) {
            avg = (drawingCoordinates[i - 1].val[name].yPosLast + drawingCoordinates[i].val[name].yPosFirst) / 2;
            if (Math.abs(drawingCoordinates[i].val[name].yPosMin - drawingCoordinates[i - 1].val[name].yPosMax) < Math.abs(drawingCoordinates[i].val[name].yPosMax - drawingCoordinates[i - 1].val[name].yPosMin)) {
                minV = _.min([avg, minV]);
            }
            else {
                maxV = _.max([avg, maxV]);
            }
        }

        if (drawingCoordinates[i + 1] != null && drawingCoordinates[i + 1].val[name] != null) {
            avg = (drawingCoordinates[i + 1].val[name].yPosFirst + drawingCoordinates[i].val[name].yPosLast) / 2;
            if (Math.abs(drawingCoordinates[i].val[name].yPosMin - drawingCoordinates[i + 1].val[name].yPosMax) < Math.abs(drawingCoordinates[i].val[name].yPosMax - drawingCoordinates[i + 1].val[name].yPosMin)) {
                minV = _.min([avg, minV]);
            }
            else {
                maxV = _.max([avg, maxV]);
            }
        }

        var yPercentMax = _.clamp((maxV - valueRange.yMinium) / (valueRange.yMaximum - valueRange.yMinium), 0, 1);
        var yPercentMin = _.clamp((minV - valueRange.yMinium) / (valueRange.yMaximum - valueRange.yMinium), 0, 1);
        var Yminum = height - margin.bottom - (yPercentMin * chartWindow.height);
        var Ymaximun = (yPercentMin * chartWindow.height) - (yPercentMax * chartWindow.height);

        p.rect(drawingCoordinates[i].point + offset, Yminum, 0.1, Ymaximun);

        return ({ x: drawingCoordinates[i].point + offset, y: height - margin.bottom - (chartWindow.height * yPercent) });
    }

    //this makes the line on the chart
    function drawSeries(p) {
        // console.log("pridemo do sm?");
        barWidth = chartWindow.width / drawingCoordinates.length;
        if (drawingCoordinates[0] != null) {
            var prevPoint = Array(Object.keys(drawingCoordinates[0].val).length).fill(0);
        }
        for (let i = 0; i < (drawingCoordinates).length; i++) {
            for (let j = 0; j < Object.keys(series).length; j++) {
                var name = Object.keys(series)[j];
                var offset = 0;

                if (drawingCoordinates[i].val[name] == null) { continue; }
                if (drawingCoordinates[i].val[name].yPos == null) { drawingCoordinates[i].val[name].yPos = valueRange.yMinium; }
                var yPercent = _.clamp((drawingCoordinates[i].val[name].yPos - valueRange.yMinium) / (valueRange.yMaximum - valueRange.yMinium), 0, 1);

                if (drawingCoordinates[i].val[name].groupSize > 1) {
                    //Grouped view
                    prevPoint[j] = groupDraw(name, yPercent, i, prevPoint[j], offset, p);
                } else {
                    if (series[name].displayMode.value == DisplayMode.histogram && i != drawingCoordinates.length - 1) {
                        //Histogram
                        histogramDraw(name, yPercent, drawingCoordinates[i].point, barWidth, p);
                    } else if (series[name].displayMode.value == DisplayMode.hollow && i != drawingCoordinates.length - 1) {
                        //HollowHistogram
                        hollowDraw(name, yPercent, drawingCoordinates[i].point, barWidth, p);
                    } else if (series[name].displayMode.value == DisplayMode.line) {
                        //LineView
                        prevPoint[j] = dewesoftDraw(name, i, prevPoint[j], offset, p);
                    }
                }
            }
        }
    }

    function calcSeriesPosition() {
        tempCoordinates = {};
        drawingCoordinates = [];

        Object.keys(dataPackage.dataStreams).forEach(function (key, index) {
            storeData(dataPackage.dataStreams[key], key);
        });
    }

    function calculateGridRange(setMin, dataPackage) {
        const setMinLog = Math.log10(_.max([setMin, 1]));
        const xMinLog = Math.log10(_.max([dataPackage.range.xMin, 1]));
        const xMaxLog = Math.log10(dataPackage.range.xMax);

        const gridRangeMinimum = (setMinLog - xMinLog) / (xMaxLog - xMinLog);

        return gridRangeMinimum;
    }

    function safeLog10(value) {
        const minValue = 1e-10; // Define a minimum value to prevent log10(0)
        return Math.log10(Math.max(value, minValue));
    }


    function getIncrementValues(p) {
        let valMinY = null;
        let valMaxY = null;
        let newMinY = null;
        let newMaxY = null;
        let valMinX = null;
        let valMaxX = null;
        let newMinX = null;
        let newMaxX = null;
        let isLogarithmic = options.xAxisScaleMode === ScaleMode.logarithmic;
        let currentMinimum;

        if (isLogarithmic) {
            // For logarithmic scale, use xMiniumLog if defined, otherwise fall back to default calculations
            currentMinimum = options.xMiniumLog !== undefined ? options.xMiniumLog / hzCoefficient :
                // Your fallback logic here for logarithmic scale if xMiniumLog is not defined
                dataPackage.range.xMin; // Example fallback, adjust as needed
        } else {
            // For linear scale, use xMinum if defined, otherwise fall back to default calculations
            currentMinimum = options.xMinum !== undefined ? options.xMinum / hzCoefficient :
                Object.values(series)[0].resize.offset >= 1 ? Object.values(series)[0].resize.offset / hzCoefficient :
                    dataPackage.range.xMin; // This is the fallback if xMinum is not defined
        }
        let currentMaximum = options.Xmaximum !== undefined ? options.Xmaximum / hzCoefficient : dataPackage.range.xMax
        let setMin = currentMinimum
        let setMax = currentMaximum;

        // console.log(`currentMinimum: ${currentMinimum}, currentMaximum: ${currentMaximum}`);
        if (options.overrideLimitX) {
            if (options.xAxisScaleMode == ScaleMode.linear) {
                gridRange.xMinium = (setMin - dataPackage.range.xMin) / (dataPackage.range.xMax - dataPackage.range.xMin);

                gridRange.xMaximum = (setMax - dataPackage.range.xMin) / (dataPackage.range.xMax - dataPackage.range.xMin);

            } else {
                const adjustLog = (value) => Math.log10(Math.max(value, 1e-10)); // Adjust the minimum value as needed

                // Calculate logarithmic values for setMin, xMin, and xMax
                const setMinLog = adjustLog(setMin);
                const xMinLog = adjustLog(dataPackage.range.xMin);
                const xMaxLog = adjustLog(dataPackage.range.xMax);

                // Calculate the normalized logarithmic range for the minimum value
                const gridRangeMinimum = (setMinLog - xMinLog) / (xMaxLog - xMinLog);

                // Update the grid range
                gridRange.xMinium = gridRangeMinimum;

                gridRange.xMaximum = (Math.log10(_.max([setMax, 1])) - Math.log10(_.max([dataPackage.range.xMin, 1]))) / (Math.log10(dataPackage.range.xMax) - Math.log10(_.max([dataPackage.range.xMin, 1])));

            }
        }
        Object.keys(dataPackage.dataStreams).forEach(function (key) {
            dataPackage.dataStreams[key].forEach(element => {
                if (element.xPos >= gridRange.xMinium && element.xPos <= gridRange.xMaximum) {
                    if (newMinY === null || element.yPos < newMinY) { newMinY = element.yPos; valMinY = element.valueY; }
                    if (newMaxY === null || element.yPos > newMaxY) { newMaxY = element.yPos; valMaxY = element.valueY; }
                    if (valMinX === null || element.valueX <= valMinX) { newMinX = element.xPos; valMinX = element.valueX; }
                    if (valMaxX === null || element.valueX >= valMaxX) { newMaxX = element.xPos; valMaxX = element.valueX; }
                }
            });
        });
        if (options.overrideLimitX) {
            valueRange.xMaximum = currentMaximum
            valueRange.xMinium = currentMinimum
        } else {
            valueRange.xMaximum = currentMaximum;
            valueRange.xMinium = currentMinimum;
        }

        overrideTick(options.Yminum, dataPackage.range?.yMin, options.Ymaximun, dataPackage.range?.yMax)
    }

    function overrideTick(yMin, yMinRange, yMax, yMaxRange) {
        const checkYmin = typeof yMin === 'number' ? yMin : yMinRange;
        const checkXMax = typeof yMax === 'number' ? yMax : yMaxRange;
        let min = checkYmin;
        let max = checkXMax;
        // console.log(`min: ${min}, max: ${max}`);
        if (isNaN(yMin)) yMin = 0;
        if (isNaN(max)) max = 1;

        let range = max - min;
        let idealTickNumber = 8;
        let roughStep = range / idealTickNumber;

        let magnitude = Math.pow(10, Math.floor(Math.log10(roughStep)));
        let residual = roughStep / magnitude;

        let tick;
        if (residual > 5) {
            tick = 10 * magnitude;
        } else if (residual > 2) {
            tick = 5 * magnitude;
        } else if (residual > 1) {
            tick = 2 * magnitude;
        } else {
            tick = magnitude;
        }

        gridTicks.y = tick;

        // console.log(`min: ${min}, max: ${max}, tick: ${tick}`);

        if (options.yAxisScaleMode === ScaleMode.linear || options.yAxisScaleMode === ScaleMode.decibel) {
            valueRange.yMinium = checkYmin;
            // valueRange.yMaximum = checkXMax + 0.1
            valueRange.yMaximum = checkXMax
        } else {
            valueRange.yMinium = checkYmin === 0 ? 0.1 : Math.log10(checkYmin);
            valueRange.yMaximum = Math.log10(checkXMax)
        }

        yAxisOffset = (0 - valueRange.yMinium) / (valueRange.yMaximum - valueRange.yMinium);
    }



    function recalculateValues(chg, p) {
        roundX = options.decimalX;
        roundY = options.decimalY;

        //recalculating values for resizing the screen
        if (dataPackage === undefined) {
            error = 'No datapoints avaliable';
            p.clear();
            displayError(p);
            return;
        }
        if (chg.chgV && chg.chgH) {
            getIncrementValues(p);
        }
        if (chg.chgH) {
            calcSeriesPosition(p);
        }
        if (error == '') {
            p.clear();
            drawGrid(p);
            drawSeries(p);
        }
    }

    return (
        <Sketch setup={setup} draw={draw} mousePressed={mouseButtonPressed} mouseReleased={mouseReleased} doubleClicked={doubleClick} />
    )
}