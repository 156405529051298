const formatNumber = (value, scientificNotation, decimalPlaces = 2, threshold = 1e-6, logarithmic = false) => {
    value = Number(value);

    if (logarithmic) {
        if (value < 1 && value > 0) {
            const scale = Math.pow(10, Math.floor(Math.log10(value)));
            // If value is below 0.01, format it to scientific notation without the trailing decimal
            if (value < 0.01) {
                // Convert scale to scientific notation directly, ensuring a simple format like "1e-3"
                let scaleInSci = scale.toExponential().replace(".0", "");
                return scaleInSci;
            }
            // For values between 0.01 and 1, return the rounded scale
            return scale.toFixed(Math.abs(Math.floor(Math.log10(scale))));
        }
        // For values >= 1, use the normal decimal format
        return value.toFixed(decimalPlaces);
    }
    // Handling for non-logarithmic values
    if (scientificNotation || (value < 1 && value > threshold)) {
        return value.toExponential(decimalPlaces);
    } else {
        return value.toFixed(decimalPlaces);
    }
};



module.exports = formatNumber;
